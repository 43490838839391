/*
IMPORTANT:
In order to preserve the uniform grid appearance, all cell styles need to have padding, margin and border sizes.
No built-in (selected, editable, highlight, flashing, invalid, loading, :focus) or user-specified CSS
classes should alter those!
*/
.slick-header.ui-state-default,.slick-headerrow.ui-state-default {
	width: 100%;
	overflow: hidden;
	border-left: 0px;
}

.slick-header-columns,.slick-headerrow-columns {
	position: relative;
	white-space: nowrap;
	cursor: default;
	overflow: hidden;
}

.slick-header-column.ui-state-default {
	position: relative;
	display: inline-block;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	height: 24px;
	line-height: 16px;
	margin: 0;
	padding: 0px;
	border-right: 1px solid var(--gray-400);
	border-left: 0px;
	border-top: 0px;
	border-bottom: 0px;
	float: left;
}

.slick-headerrow-column.ui-state-default {
	padding: 4px;
}

.slick-header-column-sorted {
	font-style: italic;
}

.slick-sort-indicator {
	display: inline-block;
	width: 8px;
	height: 5px;
	margin-left: 4px;
	margin-top: 6px;
	float: left;
}

.slick-sort-indicator-desc {
	// background: url(images/sort-desc.gif);
}

.slick-sort-indicator-asc {
	// background: url(images/sort-asc.gif);
}

.slick-resizable-handle {
	position: absolute;
	font-size: 0.1px;
	display: block;
	cursor: col-resize;
	width: 4px;
	right: 0px;
	top: 0;
	height: 100%;
}

.slick-sortable-placeholder {
	background: var(--gray-400);
}

.grid-canvas {
	position: relative;
	outline: 0;
}

.slick-row.ui-widget-content,.slick-row.ui-state-active {
	position: absolute;
	border: 0px;
	width: 100%;
}

.slick-cell,.slick-headerrow-column {
	position: absolute;
	border: 1px solid transparent;
	border-right: 1px dotted var(--gray-400);
	border-bottom-color: var(--gray-400);
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	vertical-align: middle;
	z-index: 1;
	padding: 1px 2px 2px 1px;
	margin: 0;
	white-space: nowrap;
	cursor: default;
}

.slick-group {

}

.slick-group-toggle {
	display: inline-block;
}

.slick-cell.highlighted {
	background: lightskyblue;
	background: rgba(0, 0, 255, 0.2);
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.slick-cell.flashing {
	border: 1px solid red !important;
}

.slick-cell.editable {
	z-index: 11;
	overflow: visible;
	background: $white;
	border-color: black;
	border-style: solid;
}

.slick-cell:focus {
	outline: none;
}

.slick-reorder-proxy {
	display: inline-block;
	background: blue;
	opacity: 0.15;
	filter: alpha(opacity = 15);
	cursor: move;
}

.slick-reorder-guide {
	display: inline-block;
	height: 2px;
	background: blue;
	opacity: 0.7;
	filter: alpha(opacity = 70);
}

.slick-selection {
	z-index: 10;
	position: absolute;
	border: 2px dashed black;
}
