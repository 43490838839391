.gridster {
	position: relative;
	margin: auto;
	height: 0
}

.gridster-content>ul {
	margin: 0;
	list-style: none;
	padding: 0
}

.gridster-item {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	list-style: none;
	z-index: 2;
	position: absolute;
	display: none
}

.gridster-loaded {
	-webkit-transition: height .3s;
	-moz-transition: height .3s;
	-o-transition: height .3s;
	transition: height .3s
}

.gridster-loaded .gridster-item {
	display: block;
	position: absolute;
	-webkit-transition: opacity .3s, left .3s, top .3s, width .3s, height
		.3s;
	-moz-transition: opacity .3s, left .3s, top .3s, width .3s, height .3s;
	-o-transition: opacity .3s, left .3s, top .3s, width .3s, height .3s;
	transition: opacity .3s, left .3s, top .3s, width .3s, height .3s
}

.gridster-preview-holder {
	display: none;
	z-index: 1;
	position: absolute;
	background-color: #ddd;
	border-color: #fff;
	opacity: 0.2
}

.gridster-item.gridster-item-moving,.gridster-item.gridster-preview-holder
	{
	-webkit-transition: none;
	-moz-transition: none;
	-o-transition: none;
	transition: none
}

.gridster-item.ng-leave.ng-leave-active {
	opacity: 0
}

.gridster-item.ng-enter {
	opacity: 1
}

.gridster-item-moving {
	z-index: 3
}

.gridster-item-resizable-handler {
	position: absolute;
	font-size: 1px;
	display: block
}

.handle-se {
	cursor: se-resize;
	width: 12px;
	height: 12px;
	right: 1px;
	bottom: 1px
}

.handle-ne {
	cursor: ne-resize;
	width: 12px;
	height: 12px;
	right: 1px;
	top: 1px
}

.handle-nw {
	cursor: nw-resize;
	width: 12px;
	height: 12px;
	left: 1px;
	top: 1px
}

.handle-sw {
	cursor: sw-resize;
	width: 12px;
	height: 12px;
	left: 1px;
	bottom: 1px
}

.handle-e {
	cursor: e-resize;
	width: 12px;
	height: 100%;
	right: 1px;
	top: 0
}

.handle-s {
	cursor: s-resize;
	height: 12px;
	width: 100%;
	bottom: 1px;
	left: 0
}

.handle-n {
	cursor: n-resize;
	height: 12px;
	width: 100%;
	top: 1px;
	left: 0
}

.handle-w {
	cursor: w-resize;
	width: 12px;
	left: 1px;
	top: 0;
	height: 100%
}